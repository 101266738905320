import React from "react";
import { graphql } from "gatsby";
import GatsbyImage from "gatsby-image";
import GatsbyLink from "gatsby-link";
import PageWrapper from "components/PageWrapper";
import RichText from "components/RichText";
import HollowCircularButton from "components/HollowCircularButton";
import log from "utils/log";
import * as siteTheme from "theme";
import * as textClasses from "./text.module.css";
import * as heroClasses from "./hero.module.css";

export const query = graphql`
  query {
    descriptionImage: contentfulAsset(
      contentful_id: { eq: "4MO4JDc5UBW9bUV9oR5DaR" }
    ) {
      id
      fixed {
        src
      }
    }
    heroImage: contentfulAsset(contentful_id: { eq: "eUWOc7SnrMEUg2pnsONX8" }) {
      id
      fluid {
        ...GatsbyContentfulFluid_withWebp
      }
    }
    about: allContentfulText(
      filter: { contentful_id: { eq: "12xYJ2JvGJoYaKMTA4oX72" } }
    ) {
      nodes {
        id
        text {
          raw
        }
      }
    }
    dogMeatTrade: allContentfulText(
      filter: { contentful_id: { eq: "6WiT2XbiXIM6AsT39DlowG" } }
    ) {
      nodes {
        id
        text {
          raw
        }
      }
    }
    dogMeatTradeImages: contentfulGallery(
      contentful_id: { eq: "3LF4sKbtcYskbuNHdEMTWj" }
    ) {
      id
      galleryItems {
        id
        fluid(maxWidth: 2048) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
    }
  }
`;

function present(data) {
  const seo = {
    title: "Home",
    image: data?.descriptionImage?.fixed?.src,
    description:
      "Nana's Haven in St. Petersburg, FL offers support and services for neglected and abused animals. Learn more about us today.",
  };

  const presentation = {
    seo,
    about: data?.about?.nodes[0]?.text,
    rescue: data?.rescue?.nodes[0]?.text,
    medical: data?.medical?.nodes[0]?.text,
    haven: data?.haven?.nodes[0]?.text,
    dogMeatTrade: data?.dogMeatTrade?.nodes[0]?.text,
    dogMeatTradeImages: data?.dogMeatTradeImages?.galleryItems,
    heroImage: data?.heroImage?.fluid,
  };

  log("[PAGE] presentation", presentation);

  return presentation;
}

export default function Page({ data }) {
  const { about, dogMeatTrade, dogMeatTradeImages, heroImage, seo } = present(
    data
  );

  return (
    <PageWrapper seo={seo}>
      {/* Hero ---> */}
      <div className="container mx-auto px-8 md:px-16">
        <div className={heroClasses.heroLayout}>
          <div className={heroClasses.heroText}>
            <div className={heroClasses.heroTextLayout}>
              <h1 className={heroClasses.heroTitle}>Our mission</h1>
              <div className={heroClasses.heroContent}>
                <RichText
                  json={about}
                  theme={{
                    title: {
                      color: siteTheme.heading.primary,
                      fontFamily: siteTheme.font.primary,
                    },
                  }}
                />
                <div className="mt-8">
                  <GatsbyLink to={`/about/process`}>
                    <HollowCircularButton
                      theme={{
                        borderColor: siteTheme.button.primary,
                        color: siteTheme.button.primary,
                      }}
                    >
                      Read About Our Rescue Process
                    </HollowCircularButton>
                  </GatsbyLink>
                </div>
              </div>
            </div>
          </div>
          <div className={heroClasses.heroImage}>
            <GatsbyImage fluid={heroImage} />
          </div>
        </div>
      </div>
      {/* <---- Hero */}

      <div className={textClasses.background}>
        <div className={textClasses.wrapper}>
          <div className={textClasses.layout}>
            <div className={textClasses.columns}>
              <RichText
                json={dogMeatTrade}
                theme={{
                  title: {
                    color: siteTheme.heading.primary,
                    fontFamily: siteTheme.font.primary,
                  },
                }}
              />
            </div>
          </div>
          <div className="grid gap-8 grid-cols-2 md:grid-cols-3 mt-32">
            {dogMeatTradeImages.map((image) => (
              <div key={image.id} className="col-span-1">
                <GatsbyImage fluid={image.fluid} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </PageWrapper>
  );
}
